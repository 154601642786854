export const wallets = [
    {
      id: 1,
      name: 'Metamask',
      image: '/images/wallets/metamask.svg',
    },
    {
      id: 2,
      name: 'WalletConnect',
      image: '/images/wallets/wallet_connect.svg',
    },
  ]
  